import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Loading from "./components/Loading";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
//Initialize GA4
ReactGA.initialize("G-NMJ12CWSLJ");
root.render(
  <>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </>
);
const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

reportWebVitals(SendAnalytics);